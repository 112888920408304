import React, { Component } from 'react';
import Layout from "../components/layout";
import Footer from "../components/Footer";

class portfolio extends Component {
    render() {
        return (
            <div>
                <Layout>

                </Layout>
                <Footer />
            </div>
        );
    }
}

export default portfolio;